import React from 'react'
import styled from 'styled-components'

import salesmachine from '../images/salesmachine.svg'

const SalesMachine = () => {
  return (
    <SalesMachineSection>
      <SalesMachineContainer>
        <SMImage><img src={salesmachine} alt="Business sales machine" width={280} style={{}}></img></SMImage>
        <SMInfo>
            <SMHeader>Is your online sales machine broken?</SMHeader>
            <SMText>As a business owner, do you have a fantastic product or service, but these concerns still sound familiar?</SMText>
            <SMBullets>
                <SMBullet>Customers can’t find my services</SMBullet>
                <SMBullet>Nobody is contacting</SMBullet>
                <SMBullet>Conversions are low and so is sales</SMBullet>
            </SMBullets>
        </SMInfo>
      </SalesMachineContainer>
    </SalesMachineSection>
  )
}

export default SalesMachine

const SalesMachineSection = styled.section`
    margin: 0 9.375rem;
    padding: 4rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 6rem 2rem 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 6rem 1rem 0 1rem;
    }
`

const SalesMachineContainer = styled.div`
  display: flex;

    @media screen and (max-width: 1020px){
      flex-direction: column;
    }


`

const SMImage = styled.div`
  position: relative;
  right: -80px;

  @media screen and (max-width: 1020px){
    margin-bottom: -4%;
    right: -40px;
  }

  @media screen and (max-width: 768px){
    margin-bottom: -8%;
    right: -20px;
  }
`

const SMInfo = styled.div`
  background-color: #ffdddd;
  align-self: center;
  padding: 4em 2em 2em 8em;

  @media screen and (max-width: 1020px){
      padding: 4em 2em 2em 4em;
    }

    @media screen and (max-width: 768px){
      padding: 4em 2em 2em 2em;
    }
`

const SMHeader = styled.h2`
  font-size: 2em;
  color: #2A2C2B;
  margin-bottom: 1em;
`

const SMText = styled.p`

`

const SMBullets = styled.div`
  padding: 2em;
`

const SMBullet = styled.ul`
  display: list-item;
  list-style: inside;
  margin-bottom: .8em;
  color: #2A2C2B;
`