import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  return (
    <AboutSection id="about">
      <AboutContainer>
        <AboutHeading>About</AboutHeading>
        <AboutBox>
          <AboutInfo>
            <AboutImage><StaticImage src="../images/jari-web-developer.png" alt="Jari Hillukkala Web Development freelancer" style={{MinWidth: "500px"}}/></AboutImage>
            <AboutDesc>
              <AboutGreeting>Hi, I’m Jari</AboutGreeting>
              <AboutTitle>Where would you like to take your business?</AboutTitle>
              <AboutText>I create websites and services for business owners so that they will be able to run their business more smoohtly and profitably.
              <br></br><br></br>
              My aim is to guide you towards your goals, no matter where your business is currently. Let’s have a chat to figure it out.
              </AboutText>
            </AboutDesc>
          </AboutInfo>
          <AboutValues>
            <AboutQuote>Quote</AboutQuote>
              Discovering the business problems is the first step. Let me help you find out.
          </AboutValues>

        </AboutBox>
      </AboutContainer>
    </AboutSection>
  )
}

export default About


const AboutSection = styled.section`
    margin: 4em 0;
`

const AboutContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const AboutHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const AboutBox = styled.div`
    background-color: #D9D9D9;
`

const AboutInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media screen and (max-width: 768px){
      grid-template-columns: 1fr;
    }
`

const AboutImage = styled.div`

`


const AboutDesc = styled.div`
    padding: 4em 1em 2em 4em;
    width: 85%;

    @media screen and (max-width: 1020px){
      
    }

    @media screen and (max-width: 768px){
      padding: 2em;
      width: 100%;
    }
`

const AboutGreeting = styled.h4`
    font-weight: 300;
    font-size: 1.2em;
    color: #374140;
    margin-bottom: .6em;
`

const AboutTitle = styled.h3`
    font-weight: 600;
    font-size: 1.8em;
    color: #374140;
    margin-bottom: .6em;
`

const AboutText = styled.p`
    color: #374140;

    @media screen and (max-width: 1200px){
      font-size: 1em;
    }

    @media screen and (max-width: 768px){
      font-size: 1em;
    }
`

const AboutQuote = styled.h4`
    font-size: 0.8em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: .6em;
`

const AboutValues = styled.div`
    font-size: 2.4em;
    padding: 2em 4em 4em 4em;
    background-color: #E5E5E5;
    color: #2A2C2B;
    font-weight: 800;

    @media screen and (max-width: 1020px){
      padding: 4em 1em 4em 1em;
      font-size: 1.6em;
    }
`