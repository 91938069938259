import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { HeroImage } from './HeroImage'

const Hero = () => {
  return (
    <HeroContainer>
      <HeroTextLayout>
        <HeroTextSection>
          <HeroMiniHeading>Let's</HeroMiniHeading>
          <HeroHeading>Build a website <br></br> that customers</HeroHeading>
          <HeroMasterHeading>Value</HeroMasterHeading>
        </HeroTextSection>
          <HeroText>
          I provide custom web design and development services to shape a better experience for the end-customer.
          </HeroText>
        <CTAButton primary="true" to="#work" fweight="true">Show work</CTAButton>
      </HeroTextLayout>
      <HeroImageDiv id="stacked">
        <HeroImage />
      </HeroImageDiv>
    </HeroContainer>
  )
}

export default Hero


const HeroContainer = styled.div`
    margin: 0 9.375rem;
    padding: 0 calc((100vw - 1440px) / 2);
    height: auto;
    display: flex;
    justify-content: space-between;


    @media screen and (max-width: 1020px){
      margin: 0 2rem;

      
      #stacked {
        display: initial;
        position: absolute;
        z-index: -1;
      }
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
      flex-direction: column-reverse;
    }
`

const HeroTextLayout = styled.div`
    margin: 4em 0 0 0;
    display: flex;
    flex-direction: column;
`
const HeroTextSection = styled.div`
    margin-bottom: 1.8em;
`

const HeroText = styled.p`
    color: #585F65;
    width: 85%;

    @media screen and (max-width: 1020px){
    width: 50%;
    }

    @media screen and (max-width: 768px){
    width: 70%;
    }
`

const HeroHeading = styled.h1`
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    line-height: 80%;
    color: #2A2C2B;

    @media screen and (max-width: 1020px){
      font-size: 2rem;
    }

    @media screen and (max-width: 768px){
      font-size: 1.6rem;
    }
`

const HeroMiniHeading = styled(HeroHeading)`
    color: #465D6B;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: .1rem;
`

const HeroMasterHeading = styled(HeroHeading)`
    font-size: 7.875rem;
    line-height: 80%;

    @media screen and (max-width: 1020px){
      font-size: 5.5rem;
    }

    @media screen and (max-width: 768px){
      font-size: 4.4rem;
    }
`

const HeroImageDiv = styled.div`

    z-index: -2;
    margin-left: 2em;
    align-content: center;
    opacity: .6;

    @media screen and (max-width: 768px){
      margin-left: 0;
      overflow: hidden;
      width: calc(100vw - 2.1rem);
      margin-bottom: -6rem;
    }
`

const CTAButton = styled(Button)`
    margin-top: 2em;
    text-transform: uppercase;
    font-weight: 600;
`


