import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const Contact = () => {
  return (
    <ContactSection>
      <ContactContainer>
        <ContactHeading>Contact</ContactHeading>
        <ContactInfo>
          <ContactDetails>
            <ContactSubHeading>Contact Details</ContactSubHeading>
            <ContactRow>Email</ContactRow>
            <ContactRow>jari@jarihillukkala.com</ContactRow>
            <ContactRow>Phone</ContactRow>
            <ContactRow>+358 44 24 44 699</ContactRow>
            <ContactRow>Location</ContactRow>
            <ContactRow>Turku, Finland</ContactRow>
          </ContactDetails>
          <ContactImage>
            <StaticImage src="../images/office.png" alt="Developers home office" layout="constrained" />
          </ContactImage>
        </ContactInfo>
        <ContactNote>
          <ContactNoteDesc>
            <NoteTitle>Have a question regarding my services?</NoteTitle>
            <NoteText>Feel free to send me a e-letter below and I’ll get back to you as soon as possible.</NoteText>
          </ContactNoteDesc>
        </ContactNote>
      </ContactContainer>
    </ContactSection>
  )
}

export default Contact

const ContactSection = styled.section`
    margin: 4em 0;
`

const ContactContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const ContactHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const ContactInfo = styled.div`
    background-color: #D9D9D9;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 768px){
      grid-template-columns: 1fr;
    }
`

const ContactSubHeading = styled.p`
    font-size: 1.2em;
    font-weight: 600;
    grid-column: 1 / 3;
    grid-row: 1;
    
`

const ContactDetails = styled.div`
    align-content: center;
    color: #374140;
    display: grid;
    //grid-template-columns: 2fr 1fr;
    //grid-template-rows: 40px 40px 40px;
    grid-auto-rows: 40px;
    grid-auto-columns: minmax(100px, auto);
    z-index: 4;
    justify-content: center;
    grid-gap: .5em;

    @media screen and (max-width: 1020px){

      font-size: .8em;
      grid-gap: 0em;
      grid-auto-rows: 30px;
    }

    @media screen and (max-width: 768px){

      grid-gap: 0em;
      grid-auto-rows: 30px;
      padding: 4em;
    }
`

const ContactRow = styled.p`

`

const ContactImage = styled.div`
    z-index: 3;
`

const ContactNote = styled.div`

`

const ContactNoteDesc = styled.div`
    background-color: #ECECEC;
    padding: 2em 1em 2em 3em;
    border-left: .6em solid #BDC3C7;
    margin: 2em 5em;
    color: #2A2C2B;
    line-height: 1.6;

    @media screen and (max-width: 768px){
      margin: 2em;
    }
`

const NoteTitle = styled.h3`
    font-weight: 600;
`

const NoteText = styled.p`
    width: 80%;

`
