import React from 'react'
import styled from 'styled-components'
import { projectData } from '../data/ProjectData'
import project from '../components/Project'

const Work = () => {
  return (
    <ProjectContainer id="work">
      <WorkHeading>Work</WorkHeading>
      <ProjectList>
        {projectData.map((item, index ) => (
            <Project name={item.name} index={index} to={item.tolink} key={index} labels={item.labels} backgroundColor={item.color} />
        ))}
      </ProjectList>
    </ProjectContainer>
  )
}

export default Work

const ProjectContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    justify-content: space-between;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const WorkHeading = styled.h2`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 46px;
    color: #2A2C2B;
`

const ProjectList = styled.div`
    
`

const Project = styled(project)`
    
`
