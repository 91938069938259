import * as React from "react"
import Layout from "../components/Layout"

import Hero from "../components/Hero"
import AppSeo from "../components/AppSeo"
import SalesMachine from "../components/SalesMachine"
import CallOut from "../components/CallOut"
import About from "../components/About"
import Work from "../components/Work"
import Rollout from "../components/Rollout"
import Contact from "../components/Contact"
import Mail from "../components/Mail"





const IndexPage = () => (
  <Layout>
    <AppSeo title="Home" />
    <Hero></Hero>
    <SalesMachine></SalesMachine>
    <CallOut></CallOut>
    <About></About>
    <Work></Work>
    <Rollout></Rollout>
    <Contact></Contact>
    <Mail></Mail>
  </Layout>
)

export default IndexPage
