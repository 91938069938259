import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
//import { Link } from 'gatsby'
import styled from 'styled-components'
import { Button } from "./Button"


const Project = ( item ) => {

/*   const projectColor = {
    backgroundColor: item.backgroundColor,
  } */

  const data = useStaticQuery(graphql`
    query ProjectsQuery {
      allProjectsJson {
        edges {
          node {
            img {
              childImageSharp {
                gatsbyImageData(height: 640)
              }
            }
            alt
            desc
            name
          }
        }
      }
    }
  `)

  function getProjects(data, index){
    const projectsArray = []
    data.allProjectsJson.edges.map((item, index) => {
      const image = getImage(item.node.img.childImageSharp.gatsbyImageData)
      projectsArray.push(
        <div>
          <GatsbyImage image={image} alt={item.node.alt} key={index}></GatsbyImage>
        </div>
      )
    })
    return projectsArray[index]
  }

  function getDesc(data, index){
    const descArray = []
    data.allProjectsJson.edges.forEach((item) => {
      descArray.push(
            <div>
              {item.node.desc}
            </div>
      )
    })
    return descArray[index]
  }

  return (
    <ProjectSingle>

      <ProjectInfo>
        <ProjectImageContainer>
              <ProjectImage>
                {getProjects(data, item.index)}
              </ProjectImage>
              
              <ProjectLabels>
                {item.labels.map((label, index) => (
                  <ProjectLabel name={label} key={index}>
                    {label}
                  </ProjectLabel>
                ))}
              </ProjectLabels>
        </ProjectImageContainer>
        <ProjectCTA>
          <ProjectTitle>
            <ProjectName>
              <ProjectNumber>{item.index.toLocaleString('en-US', {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false
              })}</ProjectNumber>
              {item.name}
            </ProjectName>
          </ProjectTitle>
          <ProjectButton>
            <SpecialButton to={item.to} key={item.index} primary="true" fweight="true">View project</SpecialButton>
          </ProjectButton>
        </ProjectCTA>
        <ProjectDesc>
                {/*console.log(getDesc(data, item.index))*/}
                <div>{getDesc(data, item.index)}</div>
        </ProjectDesc>
        <ProjectFooter>

        </ProjectFooter>
      </ProjectInfo>
    </ProjectSingle>
  )
}

export default Project


const ProjectSingle = styled.div`
  display: flex;
  margin-bottom: 8rem;
  margin-top: 2rem;
`

const ProjectNumber = styled.div`
  width: 5rem;
  color: #454343;
  font-size: 2rem;
  font-weight: 800;
  min-width: 4rem;

  @media screen and (max-width: 1020px){
      font-size: 1.8rem;
  }

  @media screen and (max-width: 768px){
      font-size: 1.4rem;
  }
  
`

const ProjectInfo = styled.div`
  width: 100%;
  background-color: #EFEFEF;
  border: 4px solid #273239;

`

const ProjectImageContainer = styled.div`
  height: 260px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background-color: #273239;
`

const ProjectImage= styled.div`
  width: 800px;
  opacity: .5;
  transform: rotate(10deg);
  z-index: 1;
  mix-blend-mode: soft-light;
  //filter: blur(2px);

  @media screen and (max-width: 1020px){
    width: 90%;
  }

  @media screen and (max-width: 768px){
    width: 80%;
  }
`

const ProjectLabels = styled.div`
  padding-top: 1em;
`

const ProjectLabel = styled.div`
  display: flex;
  justify-content: end;
  background-color: #BDC3C7;
  width: 4rem;
  padding: .2rem .4rem;
  margin-bottom: .4rem;
  font-size: .8rem;
`

const ProjectCTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  
  @media screen and (max-width: 1020px){
    flex-wrap: wrap;
  }
`

const ProjectTitle = styled.div`
  background-color: #fff;
  height: 2.46875rem;
  display: flex;
  align-items: center;
  //flex-grow: 2;
  width: auto;
`

const ProjectName = styled.h4`
  padding-left: 2rem;
  padding-right: 2em;
  color: #454343;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1020px){
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px){
    font-size: 0.7rem;
  }
`

const ProjectButton = styled.div`
    cursor: pointer;
    

  @media screen and (max-width: 1020px){
    margin: 0.625em 0 0.625em auto;
  }

`

const SpecialButton = styled(Button)`
    background-color: #87dabf;

  @media screen and (max-width: 768px){
    //padding: 0.625em 100%;
    
  }
`

const ProjectDesc= styled.div`
 padding: 2em 4em;
 font-size: 1rem;
 align-self: flex-end;
 z-index: 2;
 font-weight: 400;
 background-color: #E5E5E5;

 @media screen and (max-width: 1020px){
    padding: 2em 7em;
  }

  @media screen and (max-width: 768px){
    padding: 2em 2em;
  }
`

const ProjectFooter = styled.div`
  background-color: #273239;
  height: 4em;
`

