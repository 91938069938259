import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

const Mail = () => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data,e) => {
    //console.log(data)
    //console.log(e.target)

    e.preventDefault()

    try{
      const response = await fetch('https://omeafbagk6.execute-api.eu-central-1.amazonaws.com/latest/api/', { // latest/api works for this
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: { "Content-Type": "application/json" },
        redirect: "follow", // manual, *folslow, error
        referrer: "client", // no-referrer, *client
        body: JSON.stringify(data),
      })

      const answer = await response.json()

      if (answer.success) {
        alert(answer.message)
      }
      else {
        alert(answer.errors[0].msg)
      }
      
    }catch (err){
        alert('Error connecting to backend:', err)
    }

    // TODO :: Clear form
    e.target.reset()
  }

  return (
    <MailSection id="contact">
      <MailContainer>
      <MailHeading>Send me a message</MailHeading>
      <Envelop>
        <EnvelopFold>
          <EnvelopFoldInner></EnvelopFoldInner>
        </EnvelopFold>
        <EnvelopBelowFold>
          <Form>
            <SubmitForm onSubmit={handleSubmit(onSubmit)}> 
              <FormLabel>Name</FormLabel>
              <FormInput type="text" placeholder="Name" id="name" name="name" {...register("name")} ></FormInput>
              <FormLabel>Email</FormLabel>
              <FormInput type="text" placeholder="Email" id="email" name="email" {...register("email", { required: true })} ></FormInput>
              {errors.email && <span>This field is required</span>}
              <FormLabel>Message</FormLabel>
              <FormTextArea type="text" placeholder="Your message..." id="message" name="message" {...register("message")}></FormTextArea>

              <FormInputButton type="submit" value="Send message"/>
            </SubmitForm>
          </Form>
        </EnvelopBelowFold>
      </Envelop>
      </MailContainer>
    </MailSection>
  )
}

export default Mail

const MailSection = styled.section`
    margin: 4em 0;
`

const MailContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const MailHeading = styled.h3`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 28px;
    color: #2A2C2B;
`

const Envelop = styled.div`
    width: 80%;
    border: 2px solid #6B767E;
    border-bottom: 10px solid #6B767E;
    border-top: 10px solid #6B767E;
    border-radius: 20px;
    height: 500px;

    @media screen and (max-width: 1020px){
      width: 100%;
    }

    @media screen and (max-width: 768px){
      width: 100%;
    }
`

const EnvelopFold = styled.div`
    height: 120px;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    background-color: #6B767E;
    border-radius: 5px;
    padding-bottom: 5px;
`

const EnvelopFoldInner = styled.div`
    position: relative;
    top: -2px;
    justify-content: center;
    width: 100%;
    height: 94%;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    background-color: #E5E5E5;
    border-radius: 20px;

`

const EnvelopBelowFold = styled.div`

    justify-content: center;
    display: flex;

    @media screen and (max-width: 560px){
      display: initial;
    }

`

const Form = styled.div`

    font-size: 1.2em;
    width: auto;
    margin: auto;

    @media screen and (max-width: 768px){
      margin: 2em 1em;
    }
`


const SubmitForm = styled.form`
    margin: 2em;
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 3fr;
    align-items: center;

    @media screen and (max-width: 768px){
      margin: 0;
      grid-column-gap: 1em;
    }
`

const FormLabel = styled.label`


`

const FormInput = styled.input`


`

const FormInputButton = styled.input`
    margin-top: 2em;
    grid-column: 2 / 3;
    grid-row: 4 / 5;

    border: none;
    text-decoration: none;
    background-color: #0FB680;
    color: #2A2C2B;
    height: 2rem;
    font-weight: 800;
    //border-radius: 2rem;

    &:hover{
      background: rgba(33,220,175,0.95);
    }
`

const FormTextArea = styled.textarea`


`
