import React from 'react'
import styled from 'styled-components'

import { Button } from "./Button"

const CallOut = () => {
  return (
    <CallOutSection>
      <CallOutContainer>
        <CallOutTitle>Take initiative and connect the communication gap.</CallOutTitle>
        <CallOutSubHeading>Let’s reach your customers and show them how great you are!</CallOutSubHeading>
        <CallOutDivider>X</CallOutDivider>
        <CallOutParagraph>My web design and development services are built to support  your business needs online <br></br> and bring customers so that you can focus on what you do best.</CallOutParagraph>
        <CallOutParagraph style={{marginBottom: '2.5em'}}>Click below to see how we can partner up to improve your online sales channel.</CallOutParagraph>
        <CallOutButton to="/services" primary="true" fweight="true" style={{marginTop: '4em'}}>About My Services</CallOutButton>
      </CallOutContainer>
    </CallOutSection>
  )
}

export default CallOut


const CallOutSection = styled.section`

`

const CallOutContainer = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);
    text-align: center;

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const CallOutTitle = styled.h3`
    font-size: 2em;
    color: #2A2C2B;
    margin-bottom: .6em;
`

const CallOutSubHeading = styled.h4`
    font-size: 1.6em;
    font-weight: 400;
    color: #2A2C2B;
    margin-bottom: 1em;
`

const CallOutDivider = styled.div`
    font-weight: 400;
    font-size: 1.8em;
    color: #939DA3;
    margin-bottom: 1em;
    transform: rotate(90deg);
`

const CallOutParagraph = styled.p`
    font-size: 1.2em;
    font-weight: 400;
    color: #2A2C2B;
    margin-bottom: 1em;
`

const CallOutButton = styled(Button)`
    
`